<template>
  <div class="browse-portfolio-search-wrapper">
    <template v-if="shouldRender">
      <div class="browse-portfolio-search__header">
        <div class="browse-portfolio-search__header--tabs">
          <mi-tabs
            v-model="activeWorld"
          >
            <mi-tab
              v-for="(settingValue) in existingWorlds"
              :key="settingValue.key"
              :name="settingValue.key"
              :label="settingValue.label"
              @click="resetWorld"
            ></mi-tab>
          </mi-tabs>
        </div>
        <div class="browse-portfolio-search__header--btn">
          <mi-btn
            color="accent"
            disable
          >
            Send to search
          </mi-btn>
        </div>
      </div>
      <div class="browse-portfolio-search__content">
        <div class="browse-portfolio-search__content--list">
          <mi-tab-panels v-model="activeWorld">
            <mi-tab-panel :name="activeWorld" class="q-px-none">
              <template v-if="activeWorld === existingWorlds.YELLOW.key">
                <browse-portfolio-lists
                  :active-world="activeWorld"
                ></browse-portfolio-lists>
              </template>
              <template v-if="activeWorld === existingWorlds.GREEN.key">
                <browse-portfolio-lists
                  :active-world="activeWorld"
                ></browse-portfolio-lists>
              </template>
            </mi-tab-panel>
          </mi-tab-panels>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="browse-portfolio-search__not-available">
        <p>The Browse Portfolio feature is not available for the selected Product Model.</p>
      </div>
    </template>
  </div>
</template>

<script>
  import { BROWSE_PORTFOLIO_WORLDS } from '@/constants/browsePortfolio'
  import BrowsePortfolioLists from '@/components/browse-portfolio/BrowsePortfolioLists.vue'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    BROWSE_PORTFOLIO_PAGE_OPENED,
    BROWSE_PORTFOLIO_YELLOW_WORLD_ACCESS,
    BROWSE_PORTFOLIO_GREEN_WORLD_ACCESS
  } from '@/utils/analytics/constants'
  import { getCurrentInstance, onMounted, ref, watch } from 'vue'
  import { useBrowsePortfolio } from './utils/composables/useBrowsePortfolio'

  export default {
    name: 'BrowsePortfolioSearch',
    components: {
      BrowsePortfolioLists
    },
    setup() {
      const existingWorlds = ref(BROWSE_PORTFOLIO_WORLDS)
      const activeWorld = ref(BROWSE_PORTFOLIO_WORLDS.YELLOW.key)
      const { resetWorld } = useBrowsePortfolio()
      const { proxy } = getCurrentInstance()

      const shouldRender = () => {
        const productModel = proxy.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL)
        return productModel.encodedBusinessName !== null
      }

      watch(() => activeWorld.value, newValue => {
        if (newValue === existingWorlds.value.YELLOW.key) {
          recordAnalytics(BROWSE_PORTFOLIO_YELLOW_WORLD_ACCESS)
        }
        else {
          recordAnalytics(BROWSE_PORTFOLIO_GREEN_WORLD_ACCESS)
        }
      })

      onMounted(async () => {
        recordAnalytics(BROWSE_PORTFOLIO_PAGE_OPENED)
        shouldRender()
      })

      return {
        activeWorld,
        existingWorlds,
        resetWorld,
        shouldRender
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-search-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .browse-portfolio-search {
    &__header {
      width: 79%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: $mi-typography-font-family-condensed;

      &--tabs {
        .q-tab {
          padding: 0 1rem;
        }
      }
    }

    &__content {
      padding-top: 24px;
    }

    &__not-available {
      height: 15vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: $mi-typography-font-family-condensed;
      font-size: 1.25em;
    }
  }
</style>
