<template>
  <div class="browse-portfolio-list-item-wrapper">
    <div class="browse-portfolio-list-item">
      <mi-expansion-item
        class="search-results-group"
        header-class="items-center"
        :class="`browse-portfolio-list-item-group--${ color.toLowerCase() }`"
        dense
      >
        <!-- Header -->
        <template #header>
          <mi-list-item-section class="browse-portfolio-list-item-group__action" side>
            <!-- Checkbox -->
            <mi-checkbox
              :model-value="isItemSelected(item)"
              @click.stop="handleItemClick(item)"
            ></mi-checkbox>
          </mi-list-item-section>

          <!-- Title -->
          <search-results-item-title
            :code-id="item.id2"
            :name="item.name"
          ></search-results-item-title>
        </template>

        <!-- Content -->
        <mi-list separator>
          <mi-list-item
            v-for="subItem in item.componentVariants || item.choices"
            :key="subItem.id"
            class="browse-portfolio-list-item__subItem items-center q-pl-xl"
          >
            <mi-list-item-section
              class="browse-portfolio-list-item-group__action"
              side
              @click="handleSubItemClick(item, subItem)"
            >
              <mi-checkbox
                :model-value="isSubItemSelected(item, subItem)"
                class="no-pointer-events"
              ></mi-checkbox>
            </mi-list-item-section>

            <!-- Title -->
            <search-results-item-title
              :code-id="subItem.id2"
              :name="subItem.name"
            ></search-results-item-title>
          </mi-list-item>
        </mi-list>
      </mi-expansion-item>
    </div>
  </div>
</template>

<script>
  import SearchResultsItemTitle from '@/components/search/search-results/SearchResultsItemTitle.vue'
  import { useBrowsePortfolio } from './utils/composables/useBrowsePortfolio'

  export default {
    name: 'BrowsePortfolioListItemGroup',
    components: { SearchResultsItemTitle },
    props: {
      item: {
        type: Object,
        required: true
      },
      color: {
        type: String,
        required: false,
        default: 'yellow'
      }
    },
    setup() {
      const { setSearchItems, setSearchSubItems, portfolio: { searchItems } } = useBrowsePortfolio()

      const handleItemClick = (item = {}) => {
        setSearchItems(item)
      }
      const handleSubItemClick = (item, subItem) => {
        setSearchSubItems({ item, subItem })
      }

      const isSubItemSelected = (item = {}, subItem = {}) => {
        const searchData = searchItems.results

        if (!searchData.has(item.id)) return false

        const foundedItem = searchData.get(item.id)

        const searchDataType = subItem.componentId ? foundedItem.componentVariants : foundedItem.choices

        return !!searchDataType.find(choice => choice.id === subItem.id)
      }

      const isItemSelected = (item = {}) => searchItems.results.has(item.id)

      return {
        handleItemClick,
        handleSubItemClick,
        isSubItemSelected,
        isItemSelected
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-list-item {
    border: 1px solid #e6e6e6;
    font-size: .875rem;
  }

  .browse-portfolio-list-item-group--yellow ::v-deep(.mi-expansion-item__header) {
    background-color: lighten($mi-yellow-400, 35%);
  }

  .browse-portfolio-list-item-group--yellow ::v-deep(.mi-list-item) {
    background-color: lighten($mi-yellow-400, 47%);
  }

  .browse-portfolio-list-item-group--green ::v-deep(.mi-expansion-item__header) {
    background-color: lighten($mi-green-200, 9%);
  }

  .browse-portfolio-list-item-group--green ::v-deep(.mi-list-item) {
    background-color: lighten($mi-green-500, 59%);
  }
</style>
