<template>
  <div v-if="portfolio.categories && activeWorld ==='YELLOW'" class="browse-portfolio-lists-wrapper">
    <template v-for="(list) in listsYellow" :key="list.key">
      <browse-portfolio-list
        :header-color="activeWorld"
        :list="list"
        :items="getItemsYellow(list.key)"
        :pm-encoded-business-name="pmEncodedBusinessName"
      ></browse-portfolio-list>
    </template>
  </div>
  <div v-if="portfolio.highGroups && activeWorld ==='GREEN'" class="browse-portfolio-lists-wrapper">
    <template v-for="(list) in listsGreen" :key="list.key">
      <browse-portfolio-list
        :header-color="activeWorld"
        :list="list"
        :items="getItemsGreen(list.key)"
        :pm-encoded-business-name="pmEncodedBusinessName"
      ></browse-portfolio-list>
    </template>
  </div>
</template>

<script>

  import BrowsePortfolioList from '@/components/browse-portfolio/BrowsePortfolioList.vue'
  import {
    BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN,
    BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW
  } from '@/constants/browsePortfolio'
  import { computed, getCurrentInstance, onBeforeMount, ref } from 'vue'
  import { useBrowsePortfolio } from './utils/composables/useBrowsePortfolio'

  export default {
    name: 'BrowsePortfolioLists',
    components: { BrowsePortfolioList },
    props: {
      activeWorld: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup(props) {
      const listsGreen = ref(BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN)
      const listsYellow = ref(BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW)
      const pmEncodedBusinessName = ref('')
      const { fetchCategories, fetchHighGroups, portfolio } = useBrowsePortfolio()
      const { proxy } = getCurrentInstance()

      const getEncodedBusinessName = () => proxy.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL)

      const getItemsYellow = computed(() => key => {
        if (key === listsYellow.value.CATEGORIES.key) return portfolio.categories
        if (key === listsYellow.value.OPTIONS.key) return portfolio.options
        if (key === listsYellow.value.CHOICES.key) return portfolio.choices
        return []
      })

      const getItemsGreen = key => {
        if (key === listsGreen.value.HIGH_GROUPS.key) return portfolio.highGroups
        if (key === listsGreen.value.COMPONENTS.key) return portfolio.components
        if (key === listsGreen.value.COMPONENT_VARIANTS.key) return portfolio.componentVariants
        return []
      }

      onBeforeMount(async () => {
        const { encodedBusinessName } = getEncodedBusinessName()
        pmEncodedBusinessName.value = encodedBusinessName
        if (props.activeWorld === 'YELLOW') await fetchCategories(pmEncodedBusinessName.value)
        if (props.activeWorld === 'GREEN') await fetchHighGroups(pmEncodedBusinessName.value)
      })

      return {
        getItemsYellow,
        getItemsGreen,
        portfolio,
        listsGreen,
        listsYellow,
        pmEncodedBusinessName
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-lists-wrapper {
    width: 80%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
</style>
