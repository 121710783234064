import { reactive } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { getCategories, getHighGroups, getOptions, getComponents } from '@/api/rest/browse-portfolio'
import { BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN, BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW } from '@/constants/browsePortfolio'

function mapResults(data) {
  const allOptions = Array.from(data.values())
  return [].concat(...allOptions)
}

const portfolio = reactive(
  {
    categories: {
      results: [],
      size: 0
    },
    highGroups: {
      results: [],
      size: 0
    },
    options: {
      results: [],
      size: 0
    },
    choices: {
      results: [],
      size: 0,
      optionChoices: new Map()
    },
    components: {
      results: [],
      size: 0
    },
    componentVariants: {
      results: [],
      size: 0,
      componentKvs: new Map()
    },
    searchItems: {
      results: new Map(),
      size: 0
    },
    loading: false
  }
)

export const useBrowsePortfolio = () => {
  const {
    options,
    choices,
    searchItems,
    components,
    componentVariants,
    categories,
    highGroups
  } = portfolio

  const elementsKeyMapping = {
    isCategories: BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.CATEGORIES.key,
    isOptions: BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.OPTIONS.key,
    isChoices: BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.CHOICES.key,
    isHighGroups: BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.HIGH_GROUPS.key,
    isComponents: BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.COMPONENTS.key,
    isComponentVariants: BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.COMPONENT_VARIANTS.key
  }

  const resetWorld = () => {
    options.results = []
    options.size = 0

    choices.results = []
    choices.size = 0
    choices.optionChoices = new Map()

    components.results = []
    components.size = 0

    componentVariants.results = []
    componentVariants.size = 0
    componentVariants.componentKvs = new Map()

    searchItems.results = new Map()
  }

  const fetchCategories = async pmEncodedBusinessName => {
    portfolio.loading = true
    try {
      const categoriesResults = await getCategories(pmEncodedBusinessName)

      categories.results = categoriesResults
      categories.size = categoriesResults.length
    }
    finally {
      portfolio.loading = false
    }
  }

  const fetchOptions = async itemData => {
    portfolio.loading = true
    try {
      const { id, isActive, pmEncodedBusinessName } = itemData

      const results = isActive ? await getOptions(id, pmEncodedBusinessName) : []
      options.results = results.options ? results.options : []
      options.size = results.options?.length
    }
    finally {
      portfolio.loading = false
    }
  }

  const fetchChoices = ({ id, isActive }) => {
    const foundedOption = options.results.find(result => result.id === id)
    if (isActive) {
      choices.size += foundedOption.choices.length
      choices.optionChoices.set(id, foundedOption)
    }
    else {
      choices.size -= foundedOption.choices.length
      choices.optionChoices.delete(id)
      searchItems.results.delete(id)
    }

    choices.results = mapResults(choices.optionChoices)
  }

  const fetchHighGroups = async pmEncodedBusinessName => {
    portfolio.loading = true
    try {
      const highGroupsResults = await getHighGroups(pmEncodedBusinessName)

      highGroups.results = highGroupsResults
      highGroups.size = highGroupsResults.length
    }
    finally {
      portfolio.loading = false
    }
  }

  const fetchComponents = async itemData => {
    portfolio.loading = true
    try {
      const { id, isActive, pmEncodedBusinessName } = itemData
      const results = isActive ? await getComponents(id, pmEncodedBusinessName) : []

      components.results = results.components ? results.components : []
      components.size = results.components?.length
    }
    finally {
      portfolio.loading = false
    }
  }

  const fetchComponentVariants = ({ id, isActive }) => {
    const foundedComponent = components.results.find(result => result.id === id)

    if (isActive) {
      componentVariants.size += foundedComponent.componentVariants.length
      componentVariants.componentKvs.set(id, foundedComponent)
    }
    else {
      componentVariants.size -= foundedComponent.componentVariants.length
      componentVariants.componentKvs.delete(id)
      searchItems.results.delete(id)
    }

    componentVariants.results = mapResults(componentVariants.componentKvs)
  }

  const setSearchItems = data => {
    if (!searchItems.results.has(data.id)) {
      searchItems.results.set(data.id, data)
    }
    else {
      searchItems.results.delete(data.id)
    }
  }

  const setSearchSubItems = data => {
    const { item, subItem } = data

    if (!searchItems.results.has(item.id)) {
      const newSearchOption = cloneDeep(item)
      newSearchOption.choices = []
      newSearchOption.componentVariants = []
      searchItems.results.set(item.id, newSearchOption)
    }

    const { id } = subItem
    const savedItem = cloneDeep(searchItems.results.get(item.id))

    const { choices: choicesSaved, componentVariants: componentVariantsSaved } = savedItem
    const optionTarget = subItem.componentId ? componentVariantsSaved : choicesSaved
    const index = optionTarget.findIndex(option => option.id === id)

    index !== -1 ? optionTarget.splice(index, 1) : optionTarget.push(subItem)

    if (optionTarget.length) {
      searchItems.results.set(savedItem.id, savedItem)
    }
    else {
      searchItems.results.delete(savedItem.id)
    }
  }

  return {
    resetWorld,
    fetchOptions,
    fetchCategories,
    fetchChoices,
    fetchComponents,
    fetchComponentVariants,
    fetchHighGroups,
    setSearchItems,
    setSearchSubItems,
    portfolio,
    elementsKeyMapping
  }
}
