<template>
  <div class="browse-portfolio-list-item-wrapper">
    <div class="browse-portfolio-list-item">
      <mi-list-item
        :id="item.id"
        ref="list"
        :key="item.id"
        class="items-center mi-list-item"
        clickable
        @click="handleClick(item, type, color)"
      >
        <search-results-item-title :persistent="true" :code-id="item.id2" :name="item.name">
        </search-results-item-title>
        <mi-icon class="q-ml-sm" name="caret-right" size="8px"></mi-icon>
      </mi-list-item>
    </div>
  </div>
</template>

<script>
  import SearchResultsItemTitle from '@/components/search/search-results/SearchResultsItemTitle.vue'
  import {
    BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN,
    BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW
  } from '@/constants/browsePortfolio'
  import { onBeforeMount, ref } from 'vue'

  export default {
    name: 'BrowsePortfolioListItem',
    components: { SearchResultsItemTitle },
    props: {
      item: {
        type: Object,
        required: true
      },
      color: {
        type: String,
        required: false,
        default: 'yellow'
      },
      type: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['selected:item'],
    setup(props, { emit }) {
      const isActive = ref(false)
      const className = ref('')
      const classQuery = ref('')
      const list = ref('list')

      const cleanSelected = () => {
        document.querySelectorAll(classQuery.value).forEach(el => {
          el.classList.remove(className.value)
        })
        isActive.value = (list.value.$el.classList.contains(className.value))
      }

      const setSelected = type => {
        if (type === BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.CATEGORIES.key) cleanSelected()
        if (type === BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.HIGH_GROUPS.key) cleanSelected()

        isActive.value = !isActive.value

        const elClassList = list.value.$el.classList
        isActive.value ? elClassList.add(className.value) : elClassList.remove(className.value)
      }

      const handleClick = (item, type) => {
        setSelected(type)
        emit('selected:item', { id: item.id, isActive: isActive.value })
      }

      onBeforeMount(() => {
        className.value = `browse-portfolio-list-item-selected-${ props.color.toLowerCase() }`
        classQuery.value = `.${ className.value }`
      })

      return {
        handleClick,
        list,
        isActive
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-list-item {
    border: 1px solid #e6e6e6;
  }

  .browse-portfolio-list-item-selected-yellow {
    background-color: lighten($mi-yellow-400, 35%);
  }

  .browse-portfolio-list-item-selected-green {
    background-color: lighten($mi-green-200, 9%);
  }
</style>
